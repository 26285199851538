/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import ListingComponent from "../StyleComponents/ListingComponent";
import { Stack } from "@mui/material";
import {
  get_agreements,
  get_approved_agreements,
  get_all_approved_ag_mg,
  get_search_manager,
  get_search_manager_approved,
  get_search_manager_inprocess,
  get_total_agreements,
  get_unapproved_agreements,
} from "../../Services/Services";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import HamburgerManager from "./HamburgerManager";

function Listing() {
  const [Select, setSelect] = useState("New Agreement");
  const [data, setData] = useState([]);

  const { params } = useParams();

  const { refresh, auth } = useSelector((s) => s);

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  const [agreement, setAgreement] = useState({});

  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState([]);

  async function get_migrated_agreements(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_agreements(id, "Migrated");

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function get_approved_mg_agreement(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_all_approved_ag_mg(id, "Migrated");
      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function APICALL(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_agreements(id, "ALL");

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function get_approved_agreemnt(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_approved_agreements(id);

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function get_unapproved_agreement(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_unapproved_agreements(id);

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function get_all_agreements(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_total_agreements(id);

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  async function get_old_ag(id) {
    try {
      setLoading(true);
      setData([]);
      const result = await get_total_agreements(id);

      if (result.status === 200) {
        const data = result.data.ids;
        setAgreement(result.data.agreement);
        setData(data);

        setLoading(false);
      }
    } catch (error) {}
  }

  const [searchValue, setsearchValue] = useState("");
  useEffect(() => {
    setsearchValue("");
  }, [params, data]);

  async function SearchAPi(searchValue, id) {
    const search = await get_search_manager(searchValue, id);
    setData(search.data.ids);
    setAgreement(search.data.agreement);
  }

  //approved search
  async function SearchAPi_Approve(searchValue, id, type) {
    const search = await get_search_manager_approved(searchValue, id, type);
    setData(search.data.ids);
    setAgreement(search.data.agreement);
  }

  //in process serach
  async function SearchAPi_inprocess(searchValue, id, type) {
    const search = await get_search_manager_inprocess(searchValue, id, type);
    setData(search.data.ids);
    setAgreement(search.data.agreement);
  }

  useEffect(() => {
    if (params === "in-procces-ag") {
      APICALL(auth.id);
    } else if (params === "approved-ag") {
      get_approved_agreemnt(auth.id);
    } else if (params === "approved-ag-inactive") {
      get_unapproved_agreement(auth.id);
    } else if (params === "total-ag") {
      get_all_agreements(auth.id);
    } else if (params === "old-ag") {
      get_old_ag(auth.id);
    } else if (params === "mg-ag") {
      get_migrated_agreements(auth.id);
    } else if (params === "approved-ag-mg") {
      get_approved_mg_agreement(auth.id);
    }
    setsearchValue("");
  }, [refresh, params]);

  const row =
    data !== undefined &&
    data.length > 0 &&
    data.map((item) => {
      return {
        id: agreement[item].agreement_id,
        status: agreement[item].status,
        code: agreement[item].code,
        name: agreement[item].name,
        location: agreement[item].location,
        rent_amount: agreement[item].monthlyRent,
        checkbox: agreement[item].status,
        utr_number: agreement[item].utr_number,
        rent_date: agreement[item].rent_start_date,
        initiateDate: moment(agreement[item].time).format("DD-MM-YYYY"),
        type: agreement[item].type ? agreement[item].type : "New",
        address: agreement[item].address,
        city: agreement[item].city,
        state: agreement[item].state,
        renewal_status: agreement[item].renewal_status,
        // deposit: parseFloat(agreement[item].deposit).toFixed(0),
        deposit: agreement[item].deposit,
        terminate_status: agreement[item].terminate_status,
        branchCode: agreement[item].branch_code
      };
    });
  let filteredRows =
    searchValue.length > 0 && row.length > 0
      ? row.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : row;

  const navigate = useNavigate();

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }
  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <HamburgerManager />
        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={
            params === "mg-ag"
              ? "Migrated Agreements"
              : params === "in-procces-ag"
              ? "In Process"
              : params === "approved-ag"
              ? "Active / In Active Agreements"
              : params === "approved-ag-inactive"
              ? "In Active "
              : params === "approved-ag-mg"
              ? "Active / In Active Agreements"
              : params === "old-ag"
              ? "Old Agreement"
              : "Rental Agreement"
          }
          buttonText="Upload"
          buttonText1={
            params === "old-ag" ? "Add Old Agreement" : "Add Agreement"
          }
          addbtn={
            params === "approved-ag-inactive"
              ? false
              : params !== "mg-ag" ||
                auth.name.toLowerCase().includes("migration")
          }
          Table={DataTable}
          onChange={handleChange}
          dropDown={false}
          loading={loading}
          rows={filteredRows}
          searchValue={searchValue}
          addagreement={
            params === "mg-ag"
              ? "/migrate-agreement"
              : params === "old-ag"
              ? "/old-agreement"
              : "/newAgreement"
          }
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
          check={check}
          setCheck={setCheck}
        >
          {auth.name.toLowerCase().includes("migration") && (
            <button>Add Agreement</button>
          )}
        </ListingComponent>
      </Stack>
    </>
  );
}

export default Listing;
