/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ListingComponent from "../StyleComponents/ListingComponent";
import {
  get_Operations_agreements,
  get_search_agreement_operation,
  get_Operations_agreements_approved,
  get_Operations_agreements_total,
  get_search_agreement_operation_approved,
  get_search_agreement_operation_process,
} from "../../Services/Services";
import { useSelector } from "react-redux";
import OperationsTable from "./OperationsTable";

import moment from "moment";
import OperationsHamburger from "./OperationsHamburger";

const options = ["New Agreement", "Monthly Payment", "Rental"];

function SrManagerListing() {
  const { auth, refresh } = useSelector((state) => state);
  const { type } = useParams();
  const login_operations_id = auth.id;
  const [data, setData] = useState({ ids: [] });
  const getData = async (id) => {
    const response = await get_Operations_agreements(id);
    setData(response.data);
  };
  const getMigratedData = async (id) => {
    const response = await get_Operations_agreements(id, "Migrated");
    setData(response.data);
  };
  const getPendingData = async (id) => {
    const response = await get_Operations_agreements(id, "Pending");
    setData(response.data);
  };
  async function get_approved_agreements(id) {
    try {
      const response = await get_Operations_agreements_approved(id);
      setData(response.data);
    } catch (error) {}
  }
  async function get_total_agreements(id) {
    try {
      const response = await get_Operations_agreements_total(id);
      setData(response.data);
    } catch (error) {}
  }

  const [searchValue, setsearchValue] = useState("");
  async function SearchAPi(id, searchValue) {
    if (type === "in-procces-ag") {
      const search = await get_search_agreement_operation_process(
        id,
        searchValue
      );
      search.status === 200 && setData(search.data);
    } else if (type === "total-ag") {
      const search = await get_search_agreement_operation(id, searchValue);
      search.status === 200 && setData(search.data);
    } else if (type === "approved-ag") {
      const search = await get_search_agreement_operation_approved(
        id,
        searchValue
      );
      search.status === 200 && setData(search.data);
    }
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  useEffect(() => {
    if (type === "in-procces-ag") {
      getData(login_operations_id);
    } else if (type === "approved-ag") {
      get_approved_agreements(login_operations_id);
    } else if (type === "total-ag") {
      get_total_agreements(login_operations_id);
    } else if (type === "mg-ag") {
      getMigratedData(login_operations_id);
    } else if (type === "pendingList") {
      getPendingData(login_operations_id);
    }
  }, [refresh, type]);

  const navigate = useNavigate();

  const rows = data.ids.map((item) => {
    return {
      checkbox: data.agreement[item].status,
      i: data.agreement[item].id,
      id: data.agreement[item].agreement_id,
      status: data.agreement[item].status,
      renewal_status: data.agreement[item].renewal_status,
      terminate_status: data.agreement[item].terminate_status,
      code: data.agreement[item].code,
      branchCode: data.agreement[item].branch_code,
      name: data.agreement[item].name,
      location: data.agreement[item].location,
      address: data.agreement[item].address,
      rentalAmount: data.agreement[item].monthlyRent,
      deposit: parseFloat(data.agreement[item].deposit).toFixed(0),
      state: data.agreement[item].state,
      manager: data.agreement[item].manager_name,
      sr_manager: data.agreement[item].Sr_name,
      buh: data.agreement[item].buh,
      city: data.agreement[item].city,
      initiateDate: moment(data.agreement[item].time).format("DD-MM-YYYY"),
      type: data.agreement[item].type ? data.agreement[item].type : "New",
    };
  });

  let filteredRows =
    searchValue.length > 0
      ? rows.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : rows;
  return (
    <>
      {/* {data.success && ( */}
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <OperationsHamburger />
        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={
            type === "in-procces-ag"
              ? "In Process"
              : type === "mg-ag"
              ? "Migrated Agreement"
              : type === "approved-ag"
              ? "Active / In Active Agreements"
              : "Rental Agreement"
          }
          buttonText="Upload"
          options={options}
          value={"New Agreement"}
          Table={OperationsTable}
          rows={filteredRows}
          dropDown={false}
          searchValue={searchValue}
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
        />
      </Stack>
      {/* )} */}
    </>
  );
}

export default SrManagerListing;
