/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Checkbox, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Add_utr_datails,
  get_agreement_id_finance,
  notification_handler,
  populate_rent_data,
  updateMigrateAgreement,
  ApprovedByFinance,
} from "../../Services/Services";
import { setAlert, setRefreshBox } from "../../store/action/action";
import EditIcon from "@mui/icons-material/Edit";
import DialogBoxSBM from "../RentalPortal/DialogBoxSBM";
import Remark from "../RentalPortal/Remark";
function FinanceTable({ rows, setRows }) {
  const navigate = useNavigate();
  const { params, type } = useParams();

  const [ids, setIds] = useState([]);
  const [open, setopen] = useState({ open: false });
  const [agreement, setAgreement] = useState();
  const [agreementData, setAgreementData] = useState([]);
  const [agreementIds, setAgreementIds] = useState([]);
  const [code, setCode] = useState({ open: false });
  const [utr, setUtr] = useState({ utr: "", paymentDate: "" });
  const [listedHoldRow, setListedHoldRow] = useState([]);
  const { auth } = useSelector((s) => s);
  const srm_id = auth.id;
  const role = auth.role;

  const subType = "agreement";

  const [modifyDate, setModifyDate] = useState("");

  const dispatch = useDispatch();

  const getUnpaidData = async (id) => {
    let data = await get_agreement_id_finance(id);
    data?.data?.listHoldRow.length > 0 &&
      data?.data?.listHoldRow.map((object) => {
        listedHoldRow.push(object.id);
      });
  };

  const renderDetailsButton = (e) => {
    const id = e.id;
    const code = e.row.code;
    const modify_date = e.row.modify_date;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "rgb(103 185 68 / 89%)",
              color: "white",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            onClick={(event) => {
              event.stopPropagation();
              e.row.type === "Renewed"
                ? navigate(`/finance-approval-renewal/${id}`)
                : navigate(
                    e.row.status.toLowerCase().includes("termi")
                      ? `/finance-approval-terminate/${id}`
                      : `/finance-approval/${id}`
                  );
            }}
          >
            View
          </Button>
        </Box>
      </>
    );
  };

  const renderActionButton = (e) => {
    const id = e.row.i;

    const code = e.row.code;
    const modify_date = e.row.modify_date;
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {e.row.status === "Sent To Finance Team" &&
          e.row.type === "Migrated" && (
            <Grid container>
              <Grid item md={6} sx={{ color: "white !important" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "#62CDFF",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                  startIcon={<EditIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    id && navigate(`/editAgreement/${id}`, { id });
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          )}
        {e.row.status === "Approved" && e.row.utr_number === null && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "#62CDFF",
              color: "white",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            onClick={(event) => {
              setModifyDate(modify_date);
              setCode(code);
              setopen({ open: true, id: id, code });
              setAgreement(e.row);
              getUnpaidData(e.row.id);
              event.stopPropagation();
              if (params === "mg-ag") {
                navigate(`/finance-listing/mg-ag`);
              } else if (params === "mg-ag") {
                navigate(`/finance-listing/in-procces-ag`);
              }
            }}
          >
            UTR Number
          </Button>
        )}
        {(e.row.status === "Approved for Termination" ||
          e.row.status === "Terminated") && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "rgb(205 22 239)",
              color: "white",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            onClick={(e) => {
              navigate(`/balance_recovery/${id}`);
            }}
          >
            Deposit Recovery
          </Button>
        )}
      </Box>
    );
  };

  const handleSwitch = (e, data) => {
    if (agreementData.length > 0 && ids.includes(e.target.name)) {
      const filtered = agreementData.filter((item) => item.id !== data.id);
      setAgreementData(filtered);
    } else {
      agreementData.push(data);
    }
    if (agreementIds.includes(data.id)) {
      setAgreementIds(agreementIds.filter((i) => i !== data.id));
    } else {
      agreementIds.push(data.id);
    }
    if (ids.includes(e.target.name)) {
      setIds(ids.filter((i) => i !== e.target.name));
    } else {
      setIds([...ids, e.target.name]);
    }
  };

  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      width: 20,

      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "Sent To Finance Team" ? (
            <Checkbox
              onChange={(e) => handleSwitch(e, params.row)}
              name={params.id}
              checked={agreementIds.includes(params.id)}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },
    {
      field: "initiateDate",
      headerName: "Initiate Date",
      minWidth: 110,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "branchCode",
      headerName: "Branch Code",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },

    {
      field: "state",
      headerName: "State",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "manager_name",
      headerName: "Manager",
      minWidth: 160,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "srm_name",
      headerName: "Sr Manager",
      minWidth: 160,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "buh",
      headerName: "BUH",
      minWidth: 160,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "op_name",
      headerName: "Operations",
      minWidth: 160,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "deposit",
      headerName: "Deposit Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "payable_deposit",
      headerName: "Deposit Payable",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "rentalAmount",
      headerName: "Rent Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 250,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: renderActionButton,
    },
  ];

  const [remarkOpen, setRemarkOpen] = useState(false);

  const [remarkMSG, setRemarkMSG] = useState("");

  function handleSend() {
    if (remarkMSG?.length > 0) {
      const uniqueObjects = agreementData.filter((item, index, self) => {
        return self.findIndex((obj) => obj?.code === item?.code) === index;
      });

      uniqueObjects.map(async (data, i) => {
        const response = await ApprovedByFinance(
          {
            status: "Approved",
            finance_id: srm_id,
            modify_date: new Date(),
            additionalRemark: {
              remarks: remarkMSG,
              step_name: auth.name,
              user_id: auth.id,
              agreement_code: data.code,
              agreement_id: data.i,
              modified_date: new Date(),
            },
          },
          data.i,
          role,
          data.type === "Renewed" ? "renewal" : subType
        );

        if (response?.data?.success) {
          data.type === "New" || data.type === "Migrated"
            ? await notification_handler("AG15", data.i, data.code, auth.id)
            : data.type === "Renewed" &&
              (await notification_handler("RN25", data.i, data.code, auth.id));
          dispatch(
            setAlert({
              variant: "success",
              open: true,
              message: "Approved ",
            })
          );
          setRemarkOpen(false);
          setIds([]);
          dispatch(setRefreshBox());
        } else if (response.status === 201) {
          dispatch(
            setAlert({
              variant: "error",
              open: true,
              message: response.data.message,
            })
          );
        } else {
          dispatch(
            setAlert({
              variant: "error",
              open: true,
              message: "Something went wrong! Please again later.",
            })
          );
        }
      });
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required",
        })
      );
    }
  }

  const handleConfirm = async (e) => {
    // return 1
    const response = await Add_utr_datails(
      {
        utr_number: utr.utr,
        payment_date: utr.paymentDate,
        listedHoldRow,
      },
      agreement?.id
    );
    // 321,84
    if (response.data.success) {
      // settled all the unpaid entries to paid one

      //Update Status
      if (agreement?.type === "Migrated") {
        await ApprovedByFinance(
          {
            status: "Deposited",
          },
          agreement?.i,
          auth.role,
          "Migrated" // agreement id
        );

        const monthlyRentStatus = await updateMigrateAgreement(
          agreement?.i,
          "Migrated"
        );
        const popilateData = await populate_rent_data(agreement.i);
      }
      setRows((old) =>
        rows.map((data) => {
          if (data.id === open.id) {
            data.utr_number = utr.utr;
            return data;
          } else return data;
        })
      );
      setUtr({ utr: "", paymentDate: "" });
      setopen({ open: false });

      dispatch(
        setAlert({
          variant: "success",
          open: true,
          message: "UTR Details Added",
        })
      );
      agreement?.type === "New" || agreement?.type === "Migrated"
        ? await notification_handler(
            "AG19",
            agreement?.i,
            agreement?.code,
            auth.id,
            agreement?.id
          )
        : agreement?.type === "Renewed" &&
          (await notification_handler(
            "RN27",
            agreement?.i,
            agreement?.code,
            auth.id,
            agreement?.id
          ));
      window.location.reload();
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  };
  return (
    <>
      <DialogBoxSBM
        open={open.open}
        handleClose={() => {
          setListedHoldRow([]);
          setopen({ ...open, open: false });
          setUtr({ utr: "", paymentDate: "" });
        }}
        handleConfirm={handleConfirm}
        value={utr}
        setValue={setUtr}
        modifyDate={modifyDate}
      />

      {ids.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", m: 1, mx: 3 }}
            onClick={() => setRemarkOpen(true)}
          >
            Approve
          </Button>
        </Box>
      )}
      <Remark
        setIds={setIds}
        remark={remarkMSG}
        setRemark={setRemarkMSG}
        handleSend={handleSend}
        open={remarkOpen}
        handleClose={() => {
          setRemarkOpen(false);
          window.location.reload();
        }}
      />

      <Box
        sx={{
          height: "430px",
          px: 2,
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .statusCell": {
            maxHeight: "30px !important",
            minHeight: "25px !important",
            alignSelf: "center",
            mx: 1,
            textAlign: "center !important",
            borderRadius: "10px !important",
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          sx={{ color: "black !important", minWidth: "50px" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (
              parms.field === "status" &&
              (parms.row.status === "Approved" ||
                parms.row.status === "Deposited")
            ) {
              cellClass.push("green statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent To Sr Manager" ||
                parms.row.status === "Sent To BUH" ||
                parms.row.status === "Sent To Operations" ||
                parms.row.status === "Sent To Finance Team" ||
                parms.row.status === "Draft")
            ) {
              cellClass.push("yellow statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent Back From Sr Manager" ||
                parms.row.status === "Sent Back From BUH" ||
                parms.row.status === "Sent Back From Operations" ||
                parms.row.status === "Sent Back From Finance Team" ||
                parms.row.status === "Terminated By Manager" ||
                parms.row.status === "Terminated By Sr Manager" ||
                parms.row.status === "Terminated By Operations" ||
                parms.row.status === "Approved for Termination" ||
                parms.row.status ===
                  "Sent Back From Finance Team Termination" ||
                parms.row.status === "Terminated")
            ) {
              cellClass.push("red statusCell");
            }
            cellClass.push("allCell");

            return cellClass;
          }}
        ></DataGrid>
      </Box>
    </>
  );
}

export default FinanceTable;
