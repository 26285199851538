/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataFieldStyle,
  DocumentView,
  YearField,
} from "../StyleComponents/Rental";
import { MyHeader, SelectUser } from "../StyledComponent";
import { useEffect, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  get_agreement_id,
  get_data_recovery,
  get_old_agreement,
  get_renewal_recovery_data,
  send_to_bhu,
  get_remarks_by_code,
  getUserList,
} from "../../Services/Services";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import HamburgerManager from "./HamburgerManager";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 6, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"400"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function ManagerApproval() {
  const { id, params, type } = useParams();
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);
  const login_manager_id = auth.id;

  const subType = "agreement";

  const role = auth.role;

  const [agreement, setAgreement] = useState({});

  const [ids, setIds] = useState([]);

  const dispatch = useDispatch();
  const [allRemarks, setAllRemarks] = useState("");
  const [recovery, setRecovery] = useState({});
  const [SrManagerList, setSrManagerList] = useState([]);
  const [BUHUsers, setBUHUsers] = useState([]);
  const [ManagerList, setManagerList] = useState([]);

  //renewal recovery data
  const [renewalRecovery, setRenewalRecovery] = useState({});

  const [oldIds, setOldIds] = useState([]);

  const [partLabel, setPartLabel] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });

  async function get_recovery_data(id) {
    try {
      const recovery = await get_data_recovery(id);
      if (recovery.status === 200) {
        setRecovery(recovery.data.data[0]);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }

  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 && setPartLabel(oldvalue.data.agreement);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }

  async function get_renewal_recovery(id) {
    try {
      const renewalRecovery = await get_renewal_recovery_data(id);
      renewalRecovery.status === 200 &&
        setRenewalRecovery(renewalRecovery.data.data);
    } catch (error) {}
  }

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        return remark;
      }
      return;
    } catch (error) {}
  }

  async function getData(id) {
    try {
      const agreement = await get_agreement_id(id);
      if (agreement.data.success) {
        getUserList("BUH", null).then(
          (response) => {
            setBUHUsers(response.data);
          },
          (error) => {
            console.info(error);
            setBUHUsers([]);
          }
        );
        getUserList(
          "SR.MANAGER",
          agreement.data.agreement[agreement.data.ids[0]].buh_id
        ).then(
          (response) => {
            setSrManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setSrManagerList([]);
          }
        );
        getUserList(
          "MANAGER",
          agreement.data.agreement[agreement.data.ids[0]].srm_id
        ).then(
          (response) => {
            setManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setManagerList([]);
          }
        );
        if (
          agreement.data.agreement[agreement.data.ids[0]].type === "Renewed"
        ) {
          get_old_data(agreement.data.agreement[agreement.data.ids[0]].code);
          get_renewal_recovery(id);
        }

        setAgreement(agreement.data.agreement);
        setIds(agreement.data.ids);
        const remark = await get_remarks_by_aggrement_code(
          agreement.data.agreement[agreement.data.ids[0]].code
        );
        setAllRemarks(remark);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  useEffect(() => {
    getData(id);
  }, []);

  const handleSubmit = async (e) => {
    const response = await send_to_bhu(
      {
        status: "Sent To Sr Manager",
        manager_id: login_manager_id,
        modify_date: new Date(),
        additionalRemark: null,
      },
      id,
      role,
      subType
    );
    if (response.data.success) {
      dispatch(
        setAlert({
          variant: "success",
          open: true,
          message: "Agreement Sent To Sr Manager",
        })
      );
      navigate(-1);
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  };

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {ids &&
        ids.length > 0 &&
        (agreement[ids[0]].type === "Renewed" ? oldIds.length > 0 : true) && (
          <Stack sx={{ flexDirection: "row", mb: 4 }}>
            <HamburgerManager />

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}
              >
                <MyHeader>
                  RMS{" "}
                  <span className="small-heading">
                    (Rental Management System)
                  </span>
                </MyHeader>
                <Typography mt="15px" mr="15px" fontWeight="600">
                  Welcome {auth.name}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    {auth.name.toLowerCase().includes("migration")
                      ? "View Migrated Agreement"
                      : "View Agreement"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Box className="backButton">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  size={"large"}
                >
                  <ArrowCircleLeftIcon
                    sx={{ fontSize: "3rem" }}
                    color="#FFFFF !important"
                  />
                </IconButton>
              </Box>

              <Grid
                container
                sx={{ alignItems: "baseline", justifyContent: "center", mt: 3 }}
              >
                {/* Basic Details */}
                <Grid item md={10}>
                  <Heading heading={`Property Details`} />
                  <Grid container sx={{ alignItems: "baseline" }}>
                    {agreement[ids[0]].status === "Deposited" && (
                      <>
                        <Grid container sx={{ alignItems: "baseline" }}>
                          <DataFieldStyle
                            field={"Final Agreement"}
                            href={agreement[ids[0]].final_agreement}
                            name={"Final Agreement"}
                            bold={true}
                            cursor={true}
                          />
                          <DataFieldStyle
                            field={"Final Agreement Date"}
                            value={agreement[ids[0]].final_agreement_date}
                          />
                          <DataFieldStyle
                            field={"Monthly Rent Start Date"}
                            value={agreement[ids[0]].rent_start_date}
                          />
                        </Grid>
                      </>
                    )}

                    {agreement[ids[0]].site_visit_date !== null && (
                      <>
                        <Grid container sx={{ alignItems: "baseline" }}>
                          <DataFieldStyle
                            field={"Site Visit date"}
                            value={agreement[ids[0]].site_visit_date}
                          />
                          <DataFieldStyle
                            field={"Site Visit Remark"}
                            value={agreement[ids[0]].site_visit_remark}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container sx={{ alignItems: "baseline", mt: 2 }}>
                    <DataFieldStyle
                      field={"code"}
                      value={agreement[ids[0]].code}
                    />
                    
                    <DataFieldStyle
                      field={"Branch Code"}
                      value={agreement[ids[0]].branch_code}
                    />

                    <DataFieldStyle
                      field={"state"}
                      value={agreement[ids[0]].state}
                    />
                    <DataFieldStyle
                      field={"city"}
                      value={agreement[ids[0]].city}
                    />
                    <DataFieldStyle
                      field={"location"}
                      value={agreement[ids[0]].location}
                    />
                    <DataFieldStyle
                      field={"pincode"}
                      value={agreement[ids[0]].pincode}
                    />
                    <DataFieldStyle
                      field={"address"}
                      value={agreement[ids[0]].address}
                    />
                    <DataFieldStyle
                      field={"area"}
                      value={agreement[ids[0]].area + " sq. ft"}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " + partLabel[oldIds[0]].area
                      }
                    />
                    <DataFieldStyle
                      field={"lock in Month"}
                      value={agreement[ids[0]].lockInYear}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " +
                          partLabel[oldIds[0]].lockInYear
                      }
                    />
                    <DataFieldStyle
                      field={"notice period in month"}
                      value={agreement[ids[0]].noticePeriod}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " +
                          partLabel[oldIds[0]].noticePeriod
                      }
                    />
                    <DataFieldStyle
                      field={"deposit"}
                      value={agreement[ids[0]].deposit}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " + partLabel[oldIds[0]].deposit
                      }
                    />
                    <DataFieldStyle
                      field={"monthly rental"}
                      value={agreement[ids[0]].monthlyRent}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " +
                          partLabel[oldIds[0]].monthlyRent
                      }
                    />
                    <DataFieldStyle
                      field={"tenure"}
                      value={agreement[ids[0]].tenure}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Agreement Value: " + partLabel[oldIds[0]].tenure
                      }
                    />

                    {agreement[ids[0]].type === "Migrated" && (
                      <>
                        <div style={{ marginTop: "10px !important" }}></div>
                        <SelectUser
                          label={"BUH"}
                          required={true}
                          value={agreement[ids[0]].buh_id}
                          options={BUHUsers}
                          disabled={true}
                          view={true}
                        />
                        <SelectUser
                          label={"Senior Manager"}
                          required={true}
                          value={agreement[ids[0]].srm_id}
                          options={SrManagerList}
                          disabled={true}
                          view={true}
                        />
                        <SelectUser
                          label={"Manager"}
                          required={true}
                          value={agreement[ids[0]].manager_id}
                          options={ManagerList}
                          disabled={true}
                          view={true}
                        />
                      </>
                    )}
                    {agreement[ids[0]].tenure > 12 && (
                      <>
                        <Grid
                          container
                          sx={{ alignItems: "baseline", mt: 4 }}
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            sx={{ alignItems: "baseline" }}
                          >
                            <DataFieldStyle
                              field={"yearly Increment"}
                              value={agreement[ids[0]].yearlyIncrement}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Agreement Value: " +
                                  partLabel[oldIds[0]].yearlyIncrement
                              }
                            />
                          </Grid>
                          <YearField
                            year={"Year 1"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            Increment={0}
                            amount={Math.round(agreement[ids[0]].year1)}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Agreement Value: " +
                                partLabel[oldIds[0]].year1
                            }
                          />
                          <YearField
                            year={"Year 2"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year2)}
                            Increment={getIncrement(
                              agreement[ids[0]].year1,
                              agreement[ids[0]].year2,
                              agreement[ids[0]].yearlyIncrement
                            )}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year1,
                                partLabel[oldIds[0]].year2,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                          {agreement[ids[0]].tenure > 24 && (
                            <YearField
                              year={"Year 3"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year3)}
                              Increment={getIncrement(
                                agreement[ids[0]].year2,
                                agreement[ids[0]].year3,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year2,
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 36 && (
                            <YearField
                              year={"Year 4"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year4)}
                              Increment={getIncrement(
                                agreement[ids[0]].year3,
                                agreement[ids[0]].year4,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 48 && (
                            <YearField
                              year={"Year 5"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year5)}
                              Increment={getIncrement(
                                agreement[ids[0]].year4,
                                agreement[ids[0]].year5,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].year5,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 60 && (
                            <YearField
                              year={"Year 6"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year6)}
                              Increment={getIncrement(
                                agreement[ids[0]].year5,
                                agreement[ids[0]].year6,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year5,
                                  partLabel[oldIds[0]].year6,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 72 && (
                            <YearField
                              year={"Year 7"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year7)}
                              Increment={getIncrement(
                                agreement[ids[0]].year6,
                                agreement[ids[0]].year7,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year6,
                                  partLabel[oldIds[0]].year7,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 84 && (
                            <YearField
                              year={"Year 8"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year8)}
                              Increment={getIncrement(
                                agreement[ids[0]].year7,
                                agreement[ids[0]].year8,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year7,
                                  partLabel[oldIds[0]].year8,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 96 && (
                            <YearField
                              year={"Year 9"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year9)}
                              Increment={getIncrement(
                                agreement[ids[0]].year8,
                                agreement[ids[0]].year9,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year8,
                                  partLabel[oldIds[0]].year9,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 108 && (
                            <YearField
                              year={"Year 10"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year10)}
                              Increment={getIncrement(
                                agreement[ids[0]].year9,
                                agreement[ids[0]].year10,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year9,
                                  partLabel[oldIds[0]].year10,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                        </Grid>
                      </>
                    )}

                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <>
                          <Grid
                            container
                            sx={{ alignItems: "baseline", mt: 3 }}
                          >
                            <Heading heading={`Landlord Personal Details`} />
                            <DataFieldStyle
                              field={"name of lessor"}
                              value={agreement[ids[0]].name[id]}
                            />
                            <DataFieldStyle
                              field={"aadhaar number"}
                              value={agreement[ids[0]].aadharNo[id]}
                              href={agreement[ids[0]].aadhar_card[id]}
                              name={"AadharCard"}
                              // bold={true}
                              // cursor={true}
                            />
                            <DataFieldStyle
                              field={"PAN number"}
                              value={agreement[ids[0]].panNo[id]}
                              href={agreement[ids[0]].pan_card[id]}
                              name={"pan_certicate"}
                              // bold={true}
                              // cursor={true}
                            />
                            {/* <DataFieldStyle
                              field={"GST number"}
                              value={agreement[ids[0]].gstNo[id]}
                              href={agreement[ids[0]].gst[id]}
                              name={"gst"}
                              bold={true}
                              cursor={true}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Agreement Value: " +
                                  partLabel[oldIds[0]].gstNo[id]
                              }
                            /> */}
                            {agreement[ids[0]].gstNo[id] != null && (
                              <DataFieldStyle
                                field={"GST number"}
                                value={agreement[ids[0]].gstNo[id]}
                                href={agreement[ids[0]].gst[id]}
                                name={"gst"}
                                // bold={true}
                                // cursor={true}
                                partLabel={
                                  agreement[ids[0]].type === "Renewed" &&
                                  "Old Agreement Value: " +
                                    partLabel[oldIds[0]].gstNo[id]
                                }
                              />
                            )}

                            <DataFieldStyle
                              field={"mobile number"}
                              value={agreement[ids[0]].mobileNo[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Agreement Value: " +
                                  partLabel[oldIds[0]].mobileNo[id]
                              }
                            />

                            {agreement.alternateMobile != null && (
                              <DataFieldStyle
                                field={"alternate mobile"}
                                value={agreement.alternateMobile}
                                partLabel={
                                  agreement.type === "Renewed" &&
                                  "Old Agreement Value: " +
                                    partLabel[oldIds[0]].alternateMobile
                                }
                              />
                            )}
                            <DataFieldStyle
                              field={"email"}
                              value={agreement[ids[0]].email[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Agreement Value: " +
                                  partLabel[oldIds[0]].email[id]
                              }
                            />
                            <DataFieldStyle
                              field={"Percentage Share"}
                              value={`${agreement[ids[0]].percentage[id]}%`}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Agreement Value: " +
                                  partLabel[oldIds[0]].percentage[id]
                              }
                            />

                            {agreement[ids[0]].utr_number[id] != null && (
                              <DataFieldStyle
                                field={"Deposit UTR Number"}
                                value={agreement[ids[0]].utr_number[id]}
                              />
                            )}
                            {agreement[ids[0]].payment_date[id] != null && (
                              <Tooltip title="Calender" placement="right">
                                <DataFieldStyle
                                  field={"Deposit Payment Date"}
                                  value={agreement[ids[0]].payment_date[id]}
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details start here */}
                {/* <Heading heading={"Bank Details"} /> */}

                <Grid item md={10}>
                  <Grid container sx={{ alignItems: "baseline" }}>
                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <Grid container sx={{ alignItems: "baseline" }}>
                          <Heading
                            heading={`Landlord ${id + 1} Bank Details`}
                          />
                          <DataFieldStyle
                            field={"bank name"}
                            value={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{agreement[ids[0]].bankName[id]}</span>
                                <span>{agreement[ids[0]].branchName[id]}</span>
                              </div>
                            }
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              `Old Agreement Value: ${
                                partLabel[oldIds[0]].bankName[id]
                              }
                              Branch Name: ${
                                partLabel[oldIds[0]].branchName[id]
                              }`
                            }
                          />
                          <DataFieldStyle
                            field={"beneficiary name"}
                            value={agreement[ids[0]].benificiaryName[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Agreement Value: " +
                                partLabel[oldIds[0]].benificiaryName[id]
                            }
                          />
                          <DataFieldStyle
                            field={"bank A/c number"}
                            value={agreement[ids[0]].accountNo[id]}
                            href={agreement[ids[0]].cheque[id]}
                            name={"cheque"}
                            // bold={true}
                            // cursor={true}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Agreement Value: " +
                                partLabel[oldIds[0]].accountNo[id]
                            }
                          />
                          <DataFieldStyle
                            field={"Bank IFSC"}
                            value={agreement[ids[0]].ifscCode[id]}
                            // partLabel={agreement[ids[0]].branchName[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Agreement Value: " +
                                partLabel[oldIds[0]].ifscCode[id]
                            }
                          />
                          {/* <DataFieldStyle
                            field={"Branch Name"}
                            value={agreement[ids[0]].branchName[id]}
                            // partLabel={agreement[ids[0]].branchName[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Agreement Value: " +
                                partLabel[oldIds[0]].branchName[id]
                            }
                          /> */}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details Ends here */}

                {/* Bank Details Ends here */}

                {/* Document Section start here */}

                <Grid item md={10}>
                  <Grid
                    container
                    spacing={4}
                    sx={{ alignItems: "baseline", mt: 1 }}
                  >
                    <Grid item xs={12}>
                      <Heading heading={"Document View/Download"} />
                    </Grid>
                    <DocumentView
                      title={
                        agreement[ids[0]].type === "Migrated"
                          ? "Final Agreement"
                          : auth && auth.id === login_manager_id
                          ? "Draft Agreement"
                          : "Final Agreement"
                      }
                      img={
                        agreement[ids[0]].type === "Migrated"
                          ? agreement[ids[0]].final_agreement
                          : agreement[ids[0]].draft_agreement
                      }
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].draft_agreement
                      }
                    />
                    <DocumentView
                      title={"electricity bill"}
                      img={agreement[ids[0]].electricity_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].electricity_bill
                      }
                    />
                    <DocumentView
                      title={"maintenance bill"}
                      img={agreement[ids[0]].maintaince_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].maintaince_bill
                      }
                    />
                    <DocumentView
                      title={"POA"}
                      img={agreement[ids[0]].poa}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].poa
                      }
                    />
                    <DocumentView
                      title={"Property tax receipt"}
                      img={agreement[ids[0]].tax_receipt}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].tax_receipt
                      }
                    />
                    {agreement[ids[0]].leeseName.length > 1 && (
                      <DocumentView
                        title={"NOC (if multiple owner)"}
                        img={agreement[ids[0]].noc}
                        partLabel={
                          agreement[ids[0]].type === "Renewed" &&
                          partLabel[oldIds[0]].noc
                        }
                      />
                    )}
                    <DocumentView
                      title={"Property Picture"}
                      img={agreement[ids[0]].property_pic}
                    />
                  </Grid>
                </Grid>
                {agreement[ids[0]].status !== "Deposited" &&
                  agreement[ids[0]].type === "Migrated" && (
                    <Grid item md={10}>
                      <Grid container sx={{ alignItems: "baseline", mt: 2 }}>
                        <DataFieldStyle
                          field={"Final Agreement Date"}
                          value={agreement[ids[0]].final_agreement_date}
                        />
                        <DataFieldStyle
                          field={"Monthly Rent Start Date"}
                          value={agreement[ids[0]].rent_start_date}
                        />
                      </Grid>
                    </Grid>
                  )}
                {/* // Landlord assets */}

                {agreement[ids[0]].renewal_status ===
                  "Sent For Termination" && (
                  <Grid
                    item
                    container
                    sx={{ alignItems: "baseline", mt: 5 }}
                    xs={10}
                  >
                    <DocumentView
                      title={"Termination File"}
                      img={agreement[ids[0]].file}
                    />
                  </Grid>
                )}

                {agreement[ids[0]].assets && (
                  <Grid
                    item
                    container
                    sx={{ alignItems: "baseline", mt: 5 }}
                    xs={10}
                  >
                    <DataFieldStyle
                      field={"Landlord Assets"}
                      value={
                        agreement[ids[0]].assets === '""'
                          ? ""
                          : agreement[ids[0]].assets
                      }
                    />
                  </Grid>
                )}
                {/* document section ends here */}

                {allRemarks?.length > 0 && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 5 }}
                      xs={10}
                    >
                      <DataFieldStyle field={"Remark"} />
                      {allRemarks.map((remark, index) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px 10px 10px 0",
                          }}
                          key={index}
                        >
                          {remark}
                        </div>
                      ))}
                    </Grid>
                  </>
                )}

                {agreement[ids[0]].type === "Renewed" && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 5 }}
                      xs={10}
                    >
                      <DataFieldStyle
                        field={"Old Deposited Amount"}
                        value={renewalRecovery.deposited}
                      />

                      <DataFieldStyle
                        field={"Unpaid Monthly Rental"}
                        value={renewalRecovery.unpaid_amount}
                      />

                      <DataFieldStyle
                        field={"New Deposit Amount"}
                        value={renewalRecovery.new_deposit}
                      />
                      <DataFieldStyle
                        field={"New Deposit AmountReceivable/Payable"}
                        value={renewalRecovery.receivable}
                      />
                      {/* <DataFieldStyle
                        field={"Deposit Amount"}
                        value={renewalRecovery.balance_amount}
                      /> */}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Stack>
        )}
    </>
  );
}

export default ManagerApproval;
