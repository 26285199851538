/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import ListingComponent from "../../StyleComponents/ListingComponent";
import { useDispatch, useSelector } from "react-redux";
import { get_renewal_srm, get_search_renewal_srm } from "../../../Services/Services";
import { setAlert } from "../../../store/action/action";
import SRMHamburger from "../SRMHAmburger";
import moment from "moment";

export default function SrmRenwalList() {
  //search
  const [searchValue, setsearchValue] = useState('');
  const [agreements, setAgreements] = useState({})
  const [agIds, setAgIds] = useState([])
  const dispatch = useDispatch()
  const { auth, refresh } = useSelector(s => s)
  const [loading, setLoading] = useState(false)

  const rows = agIds.map(row => {
    let x = parseInt(agreements[row].tenure) //or whatever offset
    let CurrentDate = new Date(agreements[row].final_agreement_date);

    CurrentDate.setMonth(CurrentDate.getMonth() + x);
    CurrentDate.setDate(CurrentDate.getDate() - 1);

    function datediff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    return {
      id: agreements[row].id,
      status: agreements[row].status,
      renewal_status: agreements[row].renewal_status,
      terminate_status:  agreements[row].terminate_status,
      code: agreements[row].code,
      branchCode: agreements[row].branch_code,
      type: agreements[row].type,
      name: agreements[row].name,
      location: agreements[row].location,
      manager: agreements[row].manager_name,
      rentalAmount: agreements[row].monthlyRent,
      state: agreements[row].state,
      city: agreements[row].city,
      deposit: agreements[row].deposit,
      expiry_date:agreements[row].final_agreement_date != null ? moment(CurrentDate).format("DD-MM-YYYY") : "-",
      expiry_day:agreements[row].final_agreement_date != null ? datediff(new Date(), CurrentDate) : "-",
      address: agreements[row].address
    }
  })

  let filteredRows = searchValue.length > 0 ?
    rows.filter((object) =>
      JSON.stringify(object).toLowerCase()
        .indexOf(searchValue.toLowerCase()) > 0)
    :
    rows

  const APICALL = async (id) => {
    try {
      setLoading(true)
      const result = await get_renewal_srm(id)

      if (result.data.success) {
        setAgreements(result.data.agreement)
        setAgIds(result.data.ids)
        setLoading(false)

      }
    } catch (error) {
      return dispatch({ open: true, variant: "error", message: "Something Went Wrong Please Try Again Later." })
    }

  }

  useEffect(() => {
    APICALL(auth.id)
  }, [refresh])

  async function SearchAPi(searchValue, id) {
    try {
      const search = await get_search_renewal_srm(searchValue, id)
      if (search.data.success) {
        setAgIds(search.data.ids)
        setAgreements(search.data.agreement)
      }
    } catch (error) {

      dispatch(setAlert({ open: true, variant: "error", message: "Something Went Wrong Please Try Again Later." }))
    }
  }


  function handleSerachChange(e) {
    setsearchValue(e.target.value)
  }

  const navigate = useNavigate()
  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <SRMHamburger />
      <ListingComponent
          title1={<header>RMS <span className="small-heading">(Rental Management System)</span></header>}
          title={'Renewal / Termination'}
          Table={DataTable}
        dropDown={false}
        loading={loading}
        rows={filteredRows}
      searchValue={searchValue}
      setsearchValue={setsearchValue}
      handleSerachChange={handleSerachChange}
      />
    </Stack>
  </>
  )
}
